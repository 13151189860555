import React from "react"
import {Route} from "react-router-dom";
import { useSinglePrismicDocument } from '@prismicio/react'
import Menu from "./Menu"

const Header = () => {

    const [document] = useSinglePrismicDocument('gcc_global_header')

    return (
      <div className='header z-10 absolute w-full'>
        {document && document.data && document.data.logo && (
            <div
                className="border-gcc-bright-orange border-solid border-t-4 pt-3 px-4 flex justify-between md:justify-start items-center"
            >
                <Route render={({ history }) => (
                <img
                    className="h-10 md:h-11 cursor-pointer"
                    alt={"Global Custom Commerce"}
                    src= {document.data.logo.url}
                    onClick={() => { history.push('/') }}
                />
                )} />
                <Menu />
            </div>
        )}
      </div>
    )
}

export default Header;